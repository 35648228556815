:root {
  --border-radius: 12px;
  --font: "Epilogue", sans-serif;
  --font-headings: "Fraunces", serif;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html,
body {
  max-width: 100vw;
  font-size: 16px;
}

h1,
h2,
p,
i,
a,
blockquote,
.author-name a {
  text-rendering: optimizeLegibility;
}

a {
  text-decoration: none;
}

.editor {
  width: 100%;
  min-height: 60vh;
}

.editor .rc-md-editor {
  height: 100%;
}

.badge-description p:not(:last-child) {
  margin: 0 0 12px 0;
}
